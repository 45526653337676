<template>
  <div class="main">
    <van-nav-bar fixed title="详情" left-arrow @click-left="onClickLeft" />
    <template v-if="detail">
      <div class="ticketBox">
        <div class="topBox">
          <p>{{ detail.couponName }}</p>
          <p v-if="detail.couponType == 1 || detail.couponType == 4">
            (满{{ detail.transactionMinimumAmount }}元减{{
              detail.discountAmount
            }}元)
          </p>
          <p v-if="detail.couponType == 2">
            (满{{ detail.transactionMinimumAmount }}元打{{
              detail.discountPercent / 10
            }}折)
          </p>
          <p v-if="detail.couponType == 3">
            (满{{ detail.transactionMinimumAmount }}元可用)
          </p>
          <p v-if="detail.couponType == 5">
            (满{{ detail.transactionMinimumAmount }}元可用)
          </p>
        </div>
        <div class="botBox">
          <p>发券活动名称：{{ detail.stockName }}</p>
          <p>券码：{{ detail.couponNum }}</p>
        </div>
      </div>
      <div class="ticketBox ticketBox-1">
        <div class="botBox">
          <p>核销时间：{{ detail.useTime }}</p>
          <p>核销门店：{{ detail.verificationShopName }}</p>
        </div>
      </div>

      <div
        class="detailBox"
        v-if="detail.validityStartTime && detail.validityEndTime"
      >
        <span class="title">有效日期：</span>
        <span class="detailCon"
          >{{ detail.validityStartTime.split(" ")[0] }} 至
          {{ detail.validityEndTime.split(" ")[0] }}</span
        >
      </div>

      <div class="detailBox">
        <span class="title">可用时段：</span>
        <span class="detailCon">{{ detail.availableTimePeriod }}</span>
      </div>

      <div class="detailBox">
        <span class="title">使用规则：</span>
        <span class="detailCon">{{ detail.useRule }}</span>
      </div>

      <div
        class="btnBox"
        v-if="(isToday && detail.couponStatus == 2) || detail.couponStatus == 1"
      >
        <van-button
          :disabled="detail.couponStatus == 1"
          :loading="submitLoading"
          class="submitBtn"
          block
          type="info"
          loading-text="请稍后..."
          @click="handleReSubmit"
          >{{
            detail.couponStatus == 2
              ? "撤销"
              : detail.couponStatus == 1
              ? "已撤销"
              : ""
          }}</van-button
        >
      </div>
    </template>

    <div v-if="isQueried && !detail" class="errorBox">
      <van-empty description="暂无信息" />
    </div>
  </div>
</template>
<script>
import { Field, Notify, NavBar, Dialog, Empty } from "vant";
import globalStyle from "@/utils/style";
import { ticketHistoryDetial, rebackTicket } from "@/api/index";
export default {
  components: {
    vanField: Field,
    vanNavBar: NavBar,
    vanEmpty: Empty,
  },
  data() {
    return {
      disabled: false,
      submitLoading: false,
      detail: "",
      verifySnapshotId: "",
      isQueried: false,
      isToday: false,
      shopId: localStorage.getItem("userMsg")
        ? JSON.parse(localStorage.getItem("userMsg")).shopId
        : "",
    };
  },

  async created() {
    this.verifySnapshotId = this.$route.query.verifySnapshotId || "";
    await this.queryTicketDetail();
    if (this.detail.couponStatus == 2) {
      this.isToday = this.judgeIsToday();
    }
  },

  methods: {
    // 查询券详情
    queryTicketDetail() {
      return new Promise((resolve, reject) => {
        ticketHistoryDetial(this.verifySnapshotId)
          .then((res) => {
            this.detail = res.data;
            this.isQueried = true;
            resolve();
          })
          .catch((err) => {
            this.isQueried = true;
            reject();
          });
      });
    },

    // 判断核销日期是否是当天
    judgeIsToday() {
      let data = this.detail.useTime.replace(/-/g, "/");
      return (
        new Date(data).toString().slice(0, 10) ===
        new Date().toString().slice(0, 10)
      );
    },

    //   确认核销
    handleReSubmit() {
      Dialog.confirm({
        message: "确定要撤销吗？",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonColor: globalStyle.themeColor,
        cancelButtonColor: globalStyle.grayColor,
      })
        .then(async () => {
          if (!this.judgeIsToday()) {
            this.isToday = false;
            await this.queryTicketDetail();
            Notify({
              type: "warning",
              message: "已超过可撤销时间，当前不可撤销！",
            });
          } else {
            this.submitLoading = true;
            rebackTicket({
              verifySnapshotId: this.verifySnapshotId,
              shopId: this.shopId,
            })
              .then(async (res) => {
                await this.queryTicketDetail();
                this.submitLoading = false;
                Notify({
                  type: "success",
                  message: "撤销成功！",
                });
              })
              .catch((err) => {
                this.submitLoading = false;
              });
          }
        })
        .catch(() => {});
    },

    // 点击返回
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css.scss";
.main ::v-deep .van-nav-bar .van-icon {
  color: $--font-color;
}
.main {
  width: 375px;
  height: 100%;
  overflow: scroll;
  background-color: $--bg-color;
  border: 0.01px solid rgba(0, 0, 0, 0);
  padding-bottom: 90px;
  box-sizing: border-box;
  .btnBox {
    width: 100%;
    background-color: $--bg-color;
    position: fixed;
    bottom: 0;
    left: 0;
    .submitBtn {
      width: 345px;
      margin: 10px 15px 30px;
      background-color: $--theme-color;
      border-color: $--theme-color;
    }
  }

  .detailBox {
    width: 345px;
    margin: 20px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span:first-child {
      width: 80px;
      font-size: 14px;
      color: $--gray-color;
    }
    span:last-child {
      width: 265px;
      font-size: 14px;
      // color: $--font-color;
      color: $--gray-color;
      text-align: left;
    }
    .detailCon {
      white-space: pre-wrap;
      line-height: 25px;
    }
  }
  .ticketBox {
    width: 345px;
    border-radius: 10px;
    background-color: #fff;
    padding: 15px;
    margin: 60px 15px 15px;
    .botBox {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      p {
        width: 100%;
        font-size: 14px;
        color: $--font-color;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      p:first-child {
        margin-bottom: 8px;
      }
    }
    .topBox {
      width: 100%;
      height: 60px;
      border-bottom: 1px solid $--bg-color;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      p:first-child {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        color: $--font-color;
      }
      p:last-child {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        color: $--gray-color;
        margin-top: 8px;
      }
    }
  }
  .ticketBox-1 {
    margin-top: 15px;
    .botBox {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 50px;
    }
  }
}
</style>
